// Global
// Browser Prefix
@mixin browser-prefix($property, $value) {
  -webkit-#{$property}: $value;
  -khtml-#{$property}: $value;
  -moz-#{$property}: $value;
  -ms-#{$property}: $value;
  -o-#{$property}: $value;
  #{$property}: $value;
}

// Better Box
@mixin better-box($display: inline-block) {
  display: $display;
  position: relative;
}

// Full Box
@mixin full-box($display: inline-block) {
  @include better-box($display);
  clear: both;
  float: none;
  width: 100%;
} // End Full Box

.full-box {
  @include full-box;
}

// Hide
%hide, .hide {
  display: none;
}

// No Select
@mixin no-select {
  @include browser-prefix(user-select, none);
  @include browser-prefix(touch-callout, none);
}

// Effects
// Animate
@mixin animate($element: all, $time: $animate-time, $effect: ease-in-out) {
  @include browser-prefix(transition, $element $time $effect);
}

// No Tap Effect
@mixin no-tap {
  @include browser-prefix(tap-highlight-color, transparent);
}

// Aspect Ratio
// @mixin aspect-ratio($width, $height) {
//   position: relative;
//
//   &:before {
//     display: block;
//     content: "";
//     width: 100%;
//     padding-top: ($height / $width) * 100%;
//   }
//
//   > .content {
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//   }
// }
