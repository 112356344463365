* {
  cursor: url("../../../assets/images/cursors/cursor.png") 16 16, auto !important;
  cursor: url("../../../assets/images/cursors/cursor.svg") 16 16, auto !important;
  cursor: -webkit-image-set(
    url("../../../assets/images/cursors/cursor.png") 1x,
    url("../../../assets/images/cursors/cursor.png") 2x) 16 16, auto !important;
}

.dark-cursor {
  &, * {
    cursor: url("../../../assets/images/cursors/cursor-dark.png") 16 16, auto !important;
    cursor: url("../../../assets/images/cursors/cursor-dark.svg") 16 16, auto !important;
    cursor: -webkit-image-set(
      url("../../../assets/images/cursors/cursor-dark.png") 1x,
      url("../../../assets/images/cursors/cursor-dark.png") 2x) 16 16, auto !important;
  }
}

// button {
//   &, &:hover {
//     cursor: url("../../../assets/images/cursors/bolt-cursor.png") 16 16, auto !important;
//     cursor: url("../../../assets/images/cursors/bolt-cursor.svg") 16 16, auto !important;
//     cursor: -webkit-image-set(
//       url("../../../assets/images/cursors/bolt-cursor.png") 1x,
//       url("../../../assets/images/cursors/bolt-cursor.png") 2x) 16 16, auto !important;
//   }
// }
