
//0.0 BASE
//  0.1 Variables
//  0.2 Generics, Utilities, & Consolidations
//  0.3 Resets
//  0.4 Webfonts

//1.0 LAYOUT
//  1.1 Header Structure
//  1.2 Footer Structure
//  1.3 Headings
//  1.4 Forms
//  1.5 Lists
//  1.6 Pagination

//2.0 MODULES
//  2.# Page Specific

//3.0 RESPONSIVE
//  3.1 Only Computer - No tablet or below
//  3.2 Only Tablet & Above - No Mobile Phone
//  3.3 Primary Mobile - Tablet & Below
//  3.4 Secondary Mobile - Phone & Below
//  3.5 iPad Only
//  3.6 iPhone Only

//4.0 Themes
//  4.# If Applicable

// 0.0 BASE
// ----------------------------------------------------------------------------

// 0.1 Variables
// Responsive
$large-screen: 1280px;
$tablet: 1024px;
$big-phone: 880px;
$phone: 680px;
$small-phone: 480px;

// Color
$background: #000307; // Primary background color
$charcoal: #0f1217; // Primary background color
$background-alt: #1b2a3d; // Alternate background color
$border-color: rgba($background, 0.2);
$callout: #ef4469;
$callout-alt: #f7b173;
$callout-tert: #00ff42;
$error: $callout-alt;
$error-hover: lighten($error, 2.5%);
$link: #0071ff;
// $link-hover: #4a638b;
// $link-alt: rgba($background, 0.4);
$type: $background;
$type-alt: #1b2a3d;
$type-tert: #55606d;
$foreground: #FFFFFF;
// #F24B4B
// #6AD6F2

// Space
$base: 16px;
$tablet-base: 16px;
$phone-base: 16px;

$space: 2rem;
$half-space: $space / 2;
$double-space: $space * 2;
$minor-space: 1.250rem;
$major-space: $minor-space * 6;
$double-minor-space: $minor-space * 2;
$micro-space: 0.750rem;
$nano-space: $micro-space / 2;

// Fonts
$font: "hk_groteskregular";
$font-italic: "hk_groteskitalic";
$font-light: "hk_grotesklight";
$font-bold: "hk_groteskbold";
$font-bold-italic: "hk_groteskbold_italic";
$font-medium: "hk_groteskmedium";
$font-medium-italic: "hk_groteskmedium_italic";
$font-semibold: "hk_grotesksemibold";
$font-semibold-italic: "hk_grotesksemibold_italic";
$heading: "GlacialIndifference";
$heading-bold: "GlacialIndifferenceBold";
$heading-serif: "freighttext_medium";
$heading-serif-light: "freighttext_light";
$font-stack: $font, sans-serif;
$social: "SSSocialRegular";

// Misc
$animate-time: 0.2s;
$border-radius: 0.250em;

// Base Imports
@import "./assets/stylesheets/base/utilities";
@import "./assets/stylesheets/base/resets";
@import "./assets/stylesheets/base/cursor";
@import "./assets/stylesheets/base/generics";
@import "./assets/stylesheets/base/fonts";
@import "./assets/stylesheets/base/global";

// 1.0 LAYOUT - Global Structure
// ----------------------------------------------------------------------------

// Headings
@import "./assets/stylesheets/layout/headings";
@import "./assets/stylesheets/layout/forms";

// Forms
// @import "layout/forms";

// 2.0 MODULES - Page Specific
// ----------------------------------------------------------------------------
@import "./assets/stylesheets/modules/animations";

// Print
// @import "base/print";

// 3.0 RESPONSIVE
// ----------------------------------------------------------------------------

// Only Computer - No tablet or below
// @media screen and (min-width: $tablet) {} // End Only Computer

// Only Tablet & Above - No Mobile Phone
// @media screen and (min-width: $phone) {} // End Tablet & Above

// Only Big Phone & Above - No Mobile Phone
// @media screen and (min-width: $big-phone) {} // End Big Phone & Above

// Primary Mobile - Tablet & Below
// @media screen and (max-width: $tablet) {} // End Tablet & Below

// Big Phone & Below
// @media screen and (max-width: $big-phone) {} // End Big Phone & Below

// Secondary Mobile
// @media screen and (max-width: $phone) {} // End Secondary Mobile

// iPad Only
// @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {} // End iPad Only

// iPhone Only
// @media only screen and (max-device-width: 480px) {} // End iPhone Only
