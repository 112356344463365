$placeholder-color: rgba($type, 0.25);

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $placeholder-color;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $placeholder-color;;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $placeholder-color;;
}
:-moz-placeholder { /* Firefox 18- */
  color: $placeholder-color;;
}

@mixin form-element {
  @include full-box;
  border-bottom: 2px solid $border-color;
  font-family: $heading-serif;
  font-size: 1.6em;
  line-height: 1.4;
  padding: 0.25rem 0.1rem;

  &, &:hover, &:focus {
    background-color: rgba(255,255,255, 0);
  }

  // Big Phone & Below
  @media screen and (max-width: $big-phone) {
    font-size: 1.4em;
  } // End Big Phone & Below

  // Autofill Garbage
  &:-webkit-autofill {
    &, &:hover, &:focus {
      border: none;
      border-bottom: 2px solid $border-color;
      -webkit-text-fill-color: none;
      -webkit-box-shadow: 0 0 0px 1000px $foreground inset;
      transition: background-color 5000s ease-in-out 0s;

      &:not(:placeholder-shown) {
        border-color: $background;
      }
    }
  }

  // &:placeholder-shown {}
  &:not(:placeholder-shown) {
    border-color: $background;

    &:before {
      content: "";
      background-position: 0 0;
      background-image: url("/assets/images/circle-check.svg");
      position: absolute;
      height: 1em;
      width: 1em;
      background-size: contain;
    }
  }
}

label {
  @include full-box;
  font-size: 0.7em;
  font-family: $font-bold;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  margin-bottom: 0.6em;
  text-indent: 0.1rem;
}

input {
  @include form-element;
}

textarea {
  @include form-element;
  height: 6em;
  resize: none;
}

.underline-animation {
  transition: all 0.5s;
  display: inline-block;
  bottom: 0;
  left: -100%;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: $link;
}

input, textarea {
  &:focus {
    + .underline-animation {
      left: 0;
    }
  }
}
