// Freight
@font-face {
  font-family: 'freighttext_medium';
  src: url('../../../assets/fonts/freight_text_medium-webfont.woff2') format('woff2'),
       url('../../../assets/fonts/freight_text_medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'freighttext_light';
  src: url('../../../assets/fonts/freight_text_light-webfont.woff2') format('woff2'),
       url('../../../assets/fonts/freight_text_light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// HK Grotesk
@font-face {
  font-family: 'hk_groteskbold';
  src: url('../../../assets/fonts/HKGrotesk-Bold.woff2') format('woff2'),
       url('../../../assets/fonts/HKGrotesk-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'hk_groteskbold_italic';
  src: url('../../../assets/fonts/hkgrotesk-bolditalic.woff2') format('woff2'),
       url('../../../assets/fonts/hkgrotesk-bolditalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'hk_groteskitalic';
  src: url('../../../assets/fonts/hkgrotesk-italic.woff2') format('woff2'),
       url('../../../assets/fonts/hkgrotesk-italic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'hk_grotesklight';
  src: url('../../../assets/fonts/HKGrotesk-Light.woff2') format('woff2'),
       url('../../../assets/fonts/HKGrotesk-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'hk_grotesklight_italic';
  src: url('../../../assets/fonts/hkgrotesk-lightitalic.woff2') format('woff2'),
       url('../../../assets/fonts/hkgrotesk-lightitalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'hk_groteskmedium';
  src: url('../../../assets/fonts/HKGrotesk-Medium.woff2') format('woff2'),
       url('../../../assets/fonts/HKGrotesk-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'hk_groteskmedium_italic';
  src: url('../../../assets/fonts/hkgrotesk-mediumitalic.woff2') format('woff2'),
       url('../../../assets/fonts/hkgrotesk-mediumitalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'hk_groteskregular';
  src: url('../../../assets/fonts/HKGrotesk-Regular.woff2') format('woff2'),
       url('../../../assets/fonts/HKGrotesk-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'hk_grotesksemibold';
  src: url('../../../assets/fonts/HKGrotesk-SemiBold.woff2') format('woff2'),
       url('../../../assets/fonts/HKGrotesk-SemiBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'hk_grotesksemibold_italic';
  src: url('../../../assets/fonts/hkgrotesk-semibolditalic.woff2') format('woff2'),
       url('../../../assets/fonts/hkgrotesk-semibolditalic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// Glacial Indifference
@font-face {
  font-family: 'GlacialIndifference';
  src: url('../../../assets/fonts/GlacialIndifference-Regular.woff2') format('woff2'),
       url('../../../assets/fonts/GlacialIndifference-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GlacialIndifferenceBold';
  src: url('../../../assets/fonts/GlacialIndifference-Bold.woff2') format('woff2'),
       url('../../../assets/fonts/GlacialIndifference-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
