@mixin bounce-animation-keyframes {
	0%, 100% {
    @include browser-prefix(transform, translateY(-0.24em));
  }

  60% {
    @include browser-prefix(transform, translateY(0.44em));
  }
}

@-webkit-keyframes bounce-animation {
  @include bounce-animation-keyframes;
}

@keyframes bounce-animation {
  @include bounce-animation-keyframes;
}

// Marquee
@keyframes marquee {
  100% {
   transform: translateX(-100%);
  }
}

// Logo Color Animation
@mixin logo-color-animation {
  0% {
    fill: $callout;
  }

  50% {
    fill: $callout-alt;
  }

  100% {
    fill: $callout;
  }
}

@-webkit-keyframes logo-color-animation {
  @include logo-color-animation;
}

@keyframes logo-color-animation {
  @include logo-color-animation;
}

// Link Color Animation
@mixin link-color-animation {
  0% {
    color: $callout-alt;
  }

  50% {
    color: $callout;
  }

  100% {
    color: $callout-alt;
  }
}

@-webkit-keyframes link-color-animation {
  @include link-color-animation;
}

@keyframes link-color-animation {
  @include link-color-animation;
}
