// Curtain
// @mixin curtain($position: fixed) {
//   background-color: rgba(invert($background), 0.9);
//   bottom: 0;
//   display: none;
//   height: 100%;
//   left: 0;
//   position: $position;
//   top: 0;
//   width: 100%;
//   z-index: 9;
// }

// Uncomment to compile style
// .curtain {
//   @include curtain;
// }

// Modal
// @mixin modal {
//   @include horizontal-center;
//   background-color: $background;
//   display: none;
//   max-width: $phone;
//   padding: 60px;
//   top: $double-space;
//   width: 100%;
//   z-index: 10;
//
//   // Secondary Mobile - Phone & Below
//   @media screen and (max-width: $phone) {
//     top: 0;
//   } // End Secondary Mobile
// } // End modal

// Uncomment to compile style
// .modal {
//   @include modal;
// }

// Wrap
@mixin wrap($width: 90%, $max-width: auto) {
  @include better-box(flex);
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  max-width: $max-width;
  width: $width;

  .medium-wrap, .small-wrap {
    // Secondary Mobile
    @media screen and (max-width: $phone) {
      width: 100%;
    } // End Secondary Mobile
  }
} // End wrap

.wrap {
  @include wrap(80%, 70em);
}

.medium-wrap {
  @include wrap(80%, 52em);
}

.small-wrap {
  @include wrap(90%, 48em);
}
//
// .small-wrap {
//   @include wrap(80%, 42em);
// }
//
// .mini-wrap {
//   @include wrap(80%, 42em);
// }
